import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
function Seo({
  description,
  meta,
  keywords,
  title,
  ogImage,
  author,
  supportedLanguages,
  host,
}) {
  const lang = "vi";
  const originalPath = "/";
  const languages = !!supportedLanguages
    ? supportedLanguages?.map((supportedLang) => ({
        rel: "alternate",
        hrefLang: supportedLang,
        href: `${host}/${supportedLang}${originalPath}`,
      }))
    : [];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${title}`}
      meta={[
        {
          name: `google-adsense-account`,
          content: "ca-pub-2959066404553105",
        },
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: host,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          property: `og:locale`,
          content: lang,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.map((i) => i.keyword).join(`, `),
              }
            : []
        )
        .concat(meta)}
      link={[
        {
          rel: "canonical",
          href: `${host}/${lang}${originalPath}`,
        },
        {
          rel: "alternate",
          hrefLang: "x-default",
          href: `${host}${originalPath}`,
        },
        ...(!!languages ? languages : []),
      ]}
    >
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2959066404553105"
        crossorigin="anonymous"
      ></script>
      <script async defer src="https://sp.zalo.me/plugins/sdk.js"></script>
      <script
        async
        defer
        crossorigin="anonymous"
        src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v15.0&appId=242227507397753&autoLogAppEvents=1"
        nonce="tnoKt4wI"
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id=%27+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WQXN5Q3')`,
        }}
      />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WQXN5Q3"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        }}
      ></noscript>
    </Helmet>
  );
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default Seo;
